import { APICompsSchema } from "@unit/apis";
import { FilterMyTicketType } from "@unit/utility";
import { atom } from "jotai";

export const initializingAtom = atom<boolean>(true);
export const loadingAtom = atom<boolean>(false);
export const idTokenAtom = atom<string | undefined>(undefined);
export const authUserAtom = atom<APICompsSchema["StaffObject"] | null>(null);
export const selectStoreAtom = atom<APICompsSchema["FlatStoreObject"] | null>(null);
export const influencerTicketCountAtom = atom<APICompsSchema["InfluencerTicketObject"][]>([]);
export const selectInfluencerTicketIdAtom = atom<{
  chatOpen: boolean;
  influencerTicketId: string;
}>({
  chatOpen: false,
  influencerTicketId: "",
});

export const genresAtom = atom<APICompsSchema["FlatGenreObject"][] | null>(null);
export const areasAtom = atom<APICompsSchema["FlatAreaObject"][] | null>(null);

export const ticketsHeaderType = atom<0 | 1>(0);
export const ticketsTypeFilterAtom = atom<FilterMyTicketType>("all");
export const ticketsStatusFilterAtom = atom<APICompsSchema["InfluencerTicketStatusEnum"][]>([]);
