import { EmotionCache, Global } from "@emotion/react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { globalStyled, muiTheme } from "@unit/styles";
import { Provider } from "jotai";
import { AppProps } from "next/app";
import dynamic from "next/dynamic";
import { SnackbarProvider } from "notistack";
import * as React from "react";
import { QueryClient, QueryClientProvider } from "react-query";

import SnackbarCloseButton from "@/components/layout/SnackBarCloseButton";
import AuthProvider from "@/components/provider/AuthProvider";
import GlobalJotaiProvider from "@/components/provider/GlobalJotaiProvider";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 90000,
    },
  },
});

const Layout = dynamic(async () => import("@/components/Layout"), { ssr: false });

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

export default function MyApp(props: MyAppProps): JSX.Element {
  const { Component, pageProps } = props;
  const render = () => {
    return (
      <Layout noHeader={!!pageProps.noHeader}>
        <Component {...pageProps} />
      </Layout>
    );
  };

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={muiTheme}>
        <SnackbarProvider
          autoHideDuration={5000}
          anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
          maxSnack={3}
          preventDuplicate
          action={(snackbarKey) => <SnackbarCloseButton snackbarKey={snackbarKey} />}
        >
          <CssBaseline />
          <Global styles={globalStyled} />
          <Provider>
            <AuthProvider>
              <GlobalJotaiProvider>{render()}</GlobalJotaiProvider>
            </AuthProvider>
          </Provider>
        </SnackbarProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}
