import { useAtom } from "jotai";
import React, { useEffect } from "react";

import { useAuthentication } from "@/custom-hooks/use-authentication";
import { authUserAtom, initializingAtom } from "@/global-state/jotai-atom";

type Props = {
  children: React.ReactNode;
};
const AuthProvider: React.FC<Props> = (props) => {
  const { authSync } = useAuthentication();
  const [authUser] = useAtom(authUserAtom);
  const [initializing] = useAtom(initializingAtom);

  useEffect(() => {
    (async () => {
      if (initializing) {
        await authSync(false);
      }
    })();
  }, [initializing]);

  useEffect(() => {
    if (!authUser) return;
    else {
      const timeout = setTimeout(() => authSync(false), 1000 * 60 * 3);
      return () => clearTimeout(timeout);
    }
  }, [authUser?.id]);

  return <>{props.children}</>;
};

export default AuthProvider;
