import { StoreInfluencerTicketApi, StoreStaffApi } from "@unit/apis";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { useAtom } from "jotai";
import localforage from "localforage";
import { useRouter } from "next/router";
import { useSnackbar } from "notistack";
import { useCallback } from "react";

import { API_URL } from "@/global-state/firebase-settings";
import { firebaseGetAuth } from "@/global-state/firebase-settings";
import {
  authUserAtom,
  idTokenAtom,
  influencerTicketCountAtom,
  initializingAtom,
  loadingAtom,
  selectStoreAtom,
} from "@/global-state/jotai-atom";
import { FORGOT_PASS_PAGE, LOGIN_PATH, SHOP_TICKETS_PATH } from "@/global-state/store-app-path";

export const useAuthentication = () => {
  const router = useRouter();
  const { enqueueSnackbar } = useSnackbar();
  const [, setLoading] = useAtom(loadingAtom);
  const [, setIdToken] = useAtom(idTokenAtom);
  const [, setAuthUser] = useAtom(authUserAtom);
  const [, setSelectStoreAtom] = useAtom(selectStoreAtom);
  const [, setInfluecerTickets] = useAtom(influencerTicketCountAtom);
  const [, setInitializing] = useAtom(initializingAtom);

  const resetAuth = useCallback(async () => {
    setAuthUser(null);
    setIdToken(undefined);
    setLoading(false);
    if (!["/", LOGIN_PATH, FORGOT_PASS_PAGE].includes(router.pathname)) {
      setTimeout(() => router.push(LOGIN_PATH));
    }
  }, []);

  const authSync = useCallback(async (refresh: boolean) => {
    setLoading(true);
    firebaseGetAuth.onAuthStateChanged(async (user) => {
      try {
        const accessToken = (await user?.getIdToken(true)) || undefined;
        if (!accessToken) return await resetAuth();
        await setIdToken(accessToken);
        const checkApi = new StoreStaffApi(API_URL, accessToken);
        if (!refresh) {
          const member = await checkApi.getStaffProfile();
          if (!member) return await resetAuth();
          await setAuthUser(member.object);
          const storeId = await localforage.getItem("selected_store_id");
          const targetStore = member.object.stores.find((store) => store.id === storeId);
          await setSelectStoreAtom(targetStore || member.object.stores[0]);
          const influencerTicketApi = new StoreInfluencerTicketApi(API_URL, accessToken);
          const influencerTickets = await influencerTicketApi.getInfluencerTicketList({
            limit: 10000,
            storeId: member.object.stores[0]?.id,
          });
          await setInfluecerTickets(influencerTickets?.objects || []);
          setLoading(false);
          if (["/", LOGIN_PATH, FORGOT_PASS_PAGE].includes(router.pathname)) {
            setTimeout(() => router.push(SHOP_TICKETS_PATH));
          }
        }
      } catch (error: any) {
        console.log(error);
        await resetAuth();
      } finally {
        setTimeout(() => setInitializing(false), 1000);
        setLoading(false);
      }

      setLoading(false);
    });
  }, []);

  const authReFetchMember = useCallback(async () => {
    setLoading(true);
    firebaseGetAuth.onAuthStateChanged(async (user) => {
      const accessToken = (await user?.getIdToken(true)) || undefined;
      if (!accessToken) return resetAuth();
      await setIdToken(accessToken);
      const checkApi = new StoreStaffApi(accessToken);
      const member = await checkApi.getStaffProfile();
      if (!member) return resetAuth();
      await setAuthUser(member.object);
      await setSelectStoreAtom(member.object.stores[0]);
    });
    setLoading(false);
  }, []);

  // Email&Passwordでのログイン
  const authLoginWithEmail = useCallback(async (email: string, password: string) => {
    setLoading(true);
    signInWithEmailAndPassword(firebaseGetAuth, email, password)
      .then(async (cred) => {
        const linkUser = cred.user;
        const accessToken = (await linkUser.getIdToken(true)) || undefined;
        await setIdToken(accessToken);
        try {
          const checkApi = new StoreStaffApi(API_URL, accessToken);
          const member = await checkApi.getStaffProfile();
          await setAuthUser(member.object);
          await setSelectStoreAtom(member.object.stores[0]);
          enqueueSnackbar("ログインに成功しました", {
            variant: "success",
          });
          await router.push(SHOP_TICKETS_PATH);
        } catch (e) {
          enqueueSnackbar("店舗管理者ではありません。", {
            variant: "error",
          });
          return resetAuth();
        }
      })
      .catch((error) => {
        const code = error.code;
        if (code === "auth/user-not-found") {
          enqueueSnackbar("店舗管理者で登録がありませんでした", {
            variant: "error",
          });
        } else if (code === "auth/wrong-password") {
          enqueueSnackbar("パスワードが一致しませんでした。", {
            variant: "error",
          });
        } else if (code === "auth/too-many-requests") {
          enqueueSnackbar("連続して誤ったパスワードが入力されました。お時間を置いて再度お試しください。", {
            variant: "error",
          });
        } else if (code === "auth/network-request-failed") {
          enqueueSnackbar("ネットワークエラーが発生しました", {
            variant: "error",
          });
          return resetAuth();
        } else {
          enqueueSnackbar(error?.response?.data?.detail || error?.message, {
            variant: "error",
          });
        }
        return resetAuth();
      })
      .finally(() => setLoading(false));
  }, []);

  // ログアウト
  const authLogout = useCallback(async () => {
    setLoading(true);
    signOut(firebaseGetAuth)
      .then(async () => {
        enqueueSnackbar("ログアウトしました。", {
          variant: "info",
        });
        await resetAuth();
        setTimeout(() => router.push(LOGIN_PATH));
      })
      .catch((error) => {
        enqueueSnackbar(error?.message || "error", {
          variant: "error",
        });
      })
      .finally(() => setLoading(false));
  }, []);

  return { authSync, authReFetchMember, authLoginWithEmail, authLogout };
};
