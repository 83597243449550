import { APIPaths } from "../api-config";
import BaseApi from "../base-api";

export class StoreBrandApi extends BaseApi {
  async getBrandList(
    parameters: APIPaths["/store-app/brand/list"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/store-app/brand/list"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (
      await this.get("/store-app/brand/list", {
        params: parameters,
      })
    ).data;
  }
  async registerBrandNew(
    requestBody: APIPaths["/store-app/brand/new"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/store-app/brand/new"]["post"]["responses"]["201"]["content"]["application/json"]> {
    return (await this.post("/store-app/brand/new", requestBody)).data;
  }
  async getBrandById(
    id: APIPaths["/store-app/brand/{id}"]["get"]["parameters"]["path"]["id"],
  ): Promise<APIPaths["/store-app/brand/{id}"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get(`/store-app/brand/${id}`)).data;
  }
  async patchBrandById(
    id: APIPaths["/store-app/brand/{id}"]["patch"]["parameters"]["path"]["id"],
    requestBody: APIPaths["/store-app/brand/{id}"]["patch"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/store-app/brand/{id}"]["patch"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.patch(`/store-app/brand/${id}`, requestBody)).data;
  }
  async deleteBrandById(
    id: APIPaths["/store-app/brand/{id}"]["delete"]["parameters"]["path"]["id"],
  ): Promise<APIPaths["/store-app/brand/{id}"]["delete"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.delete(`/store-app/brand/${id}`)).data;
  }
  // ブランドの公開申請
  async putRequestBrandById(
    id: APIPaths["/store-app/brand/{id}/request"]["put"]["parameters"]["path"]["id"],
  ): Promise<APIPaths["/store-app/brand/{id}/request"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put(`/store-app/brand/${id}/request`)).data;
  }
  async putCancelRequestBrandById(
    id: APIPaths["/store-app/brand/{id}/request-cancel"]["put"]["parameters"]["path"]["id"],
  ): Promise<
    APIPaths["/store-app/brand/{id}/request-cancel"]["put"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.put(`/store-app/brand/${id}/request-cancel`)).data;
  }
  async putOpenRequestBrandById(
    id: APIPaths["/store-app/brand/{id}/open"]["put"]["parameters"]["path"]["id"],
  ): Promise<APIPaths["/store-app/brand/{id}/open"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put(`/store-app/brand/${id}/open`)).data;
  }
  async putCloseRequestBrandById(
    id: APIPaths["/store-app/brand/{id}/close"]["put"]["parameters"]["path"]["id"],
  ): Promise<APIPaths["/store-app/brand/{id}/close"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put(`/store-app/brand/${id}/close`)).data;
  }
}
