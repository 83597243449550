import { APICompsSchema } from "./api-config";

export const GenderEnumToJPY = (gender: APICompsSchema["GenderEnum"] | null) => {
  switch (gender) {
    default:
      return "";
    case "male":
      return "男性";
    case "female":
      return "女性";
    case "other":
      return "無回答";
  }
};

export const StaffRoleEnumToJPY = (staffRole: APICompsSchema["StaffRoleEnum"]) => {
  switch (staffRole) {
    default:
      return "";
    case "general":
      return "一般スタッフ";
    case "storeManager":
      return "店長（店舗マネージャー）";
    case "areaManager":
      return "エリアマネージャー";
    case "brandManager":
      return "ブランドマネージャー";
    case "supervisor":
      return "全体責任者";
  }
};

export const OrganizationTypeEnumToJPY = (organizationType: APICompsSchema["OrganizationTypeEnum"]) => {
  switch (organizationType) {
    default:
      return "";
    case "company":
      return "会社";
    case "soleProprietor":
      return "個人事業";
  }
};

export const DiscountTypeEnumToJPY = (discountType: APICompsSchema["DiscountTypeEnum"]) => {
  switch (discountType) {
    default:
      return "";
    case "percent":
      return "%引き";
    case "jpy":
      return "円引き";
    case "usd":
      return "ドル引き";
    case "none":
      return "未設定";
  }
};
export const ChatReportTypeEnumToJPY = (chatReportType: APICompsSchema["ChatReportTypeEnum"]) => {
  switch (chatReportType) {
    default:
      return "";
    case "suspicious":
      return "不審な内容またはスパム";
    case "sensitive":
      return "センシティブな内容を含んでいる";
    case "inappropriate":
      return "不適切または攻撃的な内容を含んでいる";
    case "other":
      return "その他";
  }
};

export const TicketTypeEnumToJPY = (ticketType: APICompsSchema["TicketTypeEnum"]) => {
  switch (ticketType) {
    default:
      return "";
    case "visit":
      return "来店用";
    case "order":
      return "通販用";
  }
};
export const StaffStatusEnumToJPY = (staffStatus: APICompsSchema["StaffStatusEnum"]) => {
  switch (staffStatus) {
    default:
      return "";
    case "waiting":
      return "利用規約同意前";
    case "active":
      return "有効";
    case "disabled":
      return "無効";
  }
};

export const InfluencerStatusEnumToJPY = (influencerStatus: APICompsSchema["InfluencerStatusEnum"]) => {
  switch (influencerStatus) {
    default:
      return "";
    case "waiting":
      return "利用規約同意前";
    case "active":
      return "有効";
    case "disabled":
      return "無効";
    case "canceled":
      return "退会済";
  }
};

export const OrganizationStatusEnumToJPY = (organizationStatus: APICompsSchema["OrganizationStatusEnum"]) => {
  switch (organizationStatus) {
    default:
      return "";
    case "waiting":
      return "申請中";
    case "rejected":
      return "差戻し";
    case "active":
      return "有効";
    case "disabled":
      return "無効";
  }
};

export const BrandStatusEnumToJPY = (brandStatus: APICompsSchema["BrandStatusEnum"]) => {
  switch (brandStatus) {
    default:
      return "";
    case "editing":
      return "編集中";
    case "waiting":
      return "審査中";
    case "rejected":
      return "差戻し";
    case "open":
      return "公開中";
    case "closed":
      return "非公開";
  }
};

export const StoreStatusEnumToJPY = (storeStatus: APICompsSchema["StoreStatusEnum"]) => {
  switch (storeStatus) {
    default:
      return "";
    case "editing":
      return "編集中";
    case "waiting":
      return "審査中";
    case "rejected":
      return "差戻し";
    case "open":
      return "公開中";
    case "closed":
      return "非公開";
  }
};

export const TicketStatusEnumToJPY = (ticketStatus: APICompsSchema["TicketStatusEnum"]) => {
  switch (ticketStatus) {
    default:
      return "";
    case "editing":
      return "編集中";
    case "waiting":
      return "審査中";
    case "rejected":
      return "差戻し";
    case "open":
      return "公開中";
    case "closed":
      return "非公開";
  }
};

export const InfluencerTicketStatusEnumToJPY = (
  influencerTicketStatus: APICompsSchema["InfluencerTicketStatusEnum"],
) => {
  switch (influencerTicketStatus) {
    default:
      return "";
    case "denied":
      return "差戻し";
    case "canceled":
      return "キャンセル済";
    case "requesting":
      return "申請中";
    case "offering":
      return "オファー中";
    case "active":
      return "利用可";
    case "scheduled":
      return "日程調整済";
    case "used":
      return "利用済";
    case "checked":
      return "PRリンク確認済";
    case "rated":
      return "評価済";
  }
};

export const InfluencerTicketScheduleStatusEnumToJPY = (
  influencerTicketScheduleStatus: APICompsSchema["InfluencerTicketScheduleStatusEnum"],
) => {
  switch (influencerTicketScheduleStatus) {
    default:
      return "";
    case "waiting":
      return "確認中";
    case "reserved":
      return "予約済";
    case "canceled":
      return "キャンセル済";
  }
};

export const InfluencerTicketPRtypeToJPY = (influencerTicketPRtypeStatus: APICompsSchema["PRMethodTypeEnum"]) => {
  switch (influencerTicketPRtypeStatus) {
    default:
      return "";
    case "all":
      return "指定なし";
    case "instagram":
      return "Instagram";
    case "tiktok":
      return "TikTok";
    case "youtube":
      return "Youtube";
    case "twitter":
      return "Twitter";
  }
};

export const InfluencerTicketAppStatusEnumToJPY = (
  influencerTicketStatus: APICompsSchema["InfluencerTicketStatusEnum"],
) => {
  switch (influencerTicketStatus) {
    default:
      return ["", ""];
    case "denied":
      return ["(来店)不採用になりました", "またのご応募をよろしくお願いします"];
    case "canceled":
      return ["(来店)キャンセルになりました", "またのご応募をよろしくお願いします"];
    case "requesting":
      return ["(来店)応募しました", "お店からの採用可否をお待ち下さい。"];
    case "offering":
      return ["(来店)オファーされました", "承認可否をお願いします"];
    case "active":
      return ["(来店)採用されました", "次に来店日時の候補をお店にお送りください。"];
    case "scheduled":
      return [
        "(来店)日程調整の候補日を送信しました。",
        "店舗からの連絡をお待ち下さい。連絡のやりとりは画面右上のチャットから行えます。",
      ];
    case "used":
      return ["(来店)来店が完了しました", "準備が整い次第、PR指定に従いPRを行ってください。"];
    case "checked":
      return ["(来店)PRが完了しました", "お疲れさまでした。お店の評価を行ってすべて完了になります。"];
    case "rated":
      return ["(来店)評価を完了しました", "お疲れさまでした。以上ですべて完了になります。"];
  }
};

export const InfluencerTicketMailOrderAppStatusEnumToJPY = (
  influencerTicketStatus: APICompsSchema["InfluencerTicketStatusEnum"],
) => {
  switch (influencerTicketStatus) {
    default:
      return ["", ""];
    case "denied":
      return ["(通販)不採用になりました", "またのご応募をよろしくお願いします"];
    case "canceled":
      return ["(通販)キャンセルになりました", "またのご応募をよろしくお願いします"];
    case "requesting":
      return ["(通販)応募しました", "お店からの採用可否をお待ち下さい。"];
    case "offering":
      return ["(通販)オファーされました", "承認可否をお願いします"];
    case "active":
      return ["(通販)採用されました", "商品発送までお待ちください。"];
    case "used":
      return ["(通販)商品が発送されました", "準備が整い次第、PR指定に従いPRを行ってください。"];
    case "checked":
      return ["(通販)PRが完了しました", "お疲れさまでした。お店の評価を行ってすべて完了になります。"];
    case "rated":
      return ["(通販)評価を完了しました", "お疲れさまでした。以上ですべて完了になります。"];
  }
};

export const InfluencerTicketAppStatusEnumToJPYForStore = (
  influencerTicketStatus: APICompsSchema["InfluencerTicketStatusEnum"],
  hasPR: boolean,
) => {
  switch (influencerTicketStatus) {
    default:
      return ["", ""];
    case "denied":
      return ["(来店)不採用済み", "不採用済みのチケットです"];
    case "canceled":
      return ["(来店)キャンセル済み", "キャンセル済みのチケットです"];
    case "requesting":
      return ["(来店)応募がありました", "採用可否の選択をお願いします。"];
    case "offering":
      return ["(来店)オファーされました", "承認可否をお願いします"];
    case "active":
      return ["(来店)採用しました", "来店日時候補が届くまでお待ちください。"];
    case "scheduled":
      return ["(来店)来店日時候補が届きました", "候補日時を確認し、来店日時を確定させてください。"];
    case "used":
      return hasPR
        ? ["(来店)PR報告がありました", "PR内容を確認してください。"]
        : ["(来店)来店が完了しました", "インフルエンサーからPR報告があるまでお待ちください。"];
    case "checked":
      return ["(来店)評価をしてください", "インフルエンサーの評価をお願いします。"];
    case "rated":
      return ["(来店)評価を完了しました", "お疲れさまでした。以上ですべて完了になります。"];
  }
};

export const InfluencerTicketAppStatusEnumToJPYOnMailOrderForStore = (
  influencerTicketStatus: APICompsSchema["InfluencerTicketStatusEnum"],
) => {
  switch (influencerTicketStatus) {
    default:
      return ["", ""];
    case "denied":
      return ["(通販)不採用済み", "不採用済みのチケットです"];
    case "canceled":
      return ["(通販)キャンセル済み", "キャンセル済みのチケットです"];
    case "requesting":
      return ["(通販)応募がありました", "採用可否の選択をお願いします。"];
    case "offering":
      return ["(通販)オファーされました", "承認可否をお願いします"];
    case "active":
      return ["(通販)採用しました", "商品の発送をお願いします。"];
    case "scheduled":
      return ["(通販)商品の発送を完了しました", "インフルエンサーからPR報告があるまでお待ちください。"];
    case "used":
      return ["(通販)PR報告がありました", "PR内容を確認してください。"];
    case "checked":
      return ["(通販)評価をしてください", "インフルエンサーの評価をお願いします。"];
    case "rated":
      return ["(通販)評価を完了しました", "お疲れさまでした。以上ですべて完了になります。"];
  }
};
