import { css } from "@emotion/react";

export const globalStyled = css`
  html {
    visibility: hidden;
  }
  html.wf-active {
    visibility: visible;
  }
  body {
    font-family: "kinto-sans", sans-serif;
    padding: 0;
    margin: 0;
    background-color: #f8fafb;
    font-style: normal;
    font-weight: normal;
    min-height: 100vh;
    word-break: break-all;
  }

  a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      color: inherit;
    }
  }

  .firebase-emulator-warning {
    display: none;
  }
`;
