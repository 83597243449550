import { AreaApi } from "@unit/apis";
import { useAtom } from "jotai";
import { useSnackbar } from "notistack";
import { useCallback, useMemo } from "react";

import { API_URL } from "@/global-state/firebase-settings";
import { areasAtom, idTokenAtom, loadingAtom } from "@/global-state/jotai-atom";

export const useAreaApi = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [, setLoading] = useAtom(loadingAtom);
  const [, setAreas] = useAtom(areasAtom);
  const [idToken] = useAtom(idTokenAtom);
  const StoreAreaApi = useMemo(() => new AreaApi(API_URL, idToken), [idToken]);

  const getAreaList = useCallback(async () => {
    await setLoading(true);
    try {
      setTimeout(async () => {
        const result = await StoreAreaApi.getAreaList({});
        return await setAreas(result.objects);
      }, 1);
    } catch (e: any) {
      enqueueSnackbar(e?.response?.data?.message || e?.response?.data?.devMessage || "", {
        variant: "error",
      });
    } finally {
      await setLoading(false);
    }
  }, [StoreAreaApi]);

  return {
    getAreaList,
  };
};
