import { GenreApi } from "@unit/apis";
import { useAtom } from "jotai";
import { useSnackbar } from "notistack";
import { useCallback, useMemo } from "react";

import { API_URL } from "@/global-state/firebase-settings";
import { genresAtom, idTokenAtom, loadingAtom } from "@/global-state/jotai-atom";

export const useGenreApi = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [, setGenres] = useAtom(genresAtom);
  const [, setLoading] = useAtom(loadingAtom);
  const [idToken] = useAtom(idTokenAtom);
  const AdminGenreApi = useMemo(() => new GenreApi(API_URL, idToken), [idToken]);

  const getGenreList = useCallback(async () => {
    await setLoading(true);
    try {
      setTimeout(async () => {
        const result = await AdminGenreApi.getGenreList();
        return await setGenres(result.objects);
      }, 200);
    } catch (e: any) {
      enqueueSnackbar(e?.response?.data?.message || e?.response?.data?.devMessage || "", {
        variant: "error",
      });
    } finally {
      await setLoading(false);
    }
  }, [AdminGenreApi]);

  return {
    getGenreList,
  };
};
