import { initializeApp, getApps, getApp } from "firebase/app";
import { Auth, getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getStorage, connectStorageEmulator } from "firebase/storage";

const firebaseConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
};
const firebaseApp = !getApps().length ? initializeApp(firebaseConfig) : getApp();

export const firebaseGetAuth: Auth = getAuth(firebaseApp);

if (process.env.FIREBASE_AUTH_TENANT_ID && process.env.APP_ENV !== "local") {
  firebaseGetAuth.tenantId = process.env.FIREBASE_AUTH_TENANT_ID;
}
firebaseGetAuth.languageCode = "ja";

export const API_URL = process.env.API_URL || "";
export const db = getFirestore(firebaseApp);

export default firebaseApp;

if (typeof window !== "undefined" && process.env.APP_ENV === "local") {
  connectFirestoreEmulator(getFirestore(), "localhost", 8282);
  connectAuthEmulator(getAuth(), "http://localhost:9099");
  connectStorageEmulator(getStorage(), "localhost", 9199);
}
