import { APIPaths } from "../api-config";
import BaseApi from "../base-api";

export class StoreInfluencerTicketApi extends BaseApi {
  async getInfluencerTicketList(
    parameters: APIPaths["/store-app/influencer-ticket/list"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/store-app/influencer-ticket/list"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (
      await this.get("/store-app/influencer-ticket/list", {
        params: parameters,
      })
    ).data;
  }
  async getInfluencerTicketById(
    id: APIPaths["/store-app/influencer-ticket/{id}"]["get"]["parameters"]["path"]["id"],
  ): Promise<APIPaths["/store-app/influencer-ticket/{id}"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get(`/store-app/influencer-ticket/${id}`)).data;
  }
  async putInfluencerTicketRejectionById(
    id: APIPaths["/store-app/influencer-ticket/{id}/request-rejection"]["put"]["parameters"]["path"]["id"],
    requestBody: APIPaths["/store-app/influencer-ticket/{id}/request-rejection"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<
    APIPaths["/store-app/influencer-ticket/{id}/request-rejection"]["put"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.put(`/store-app/influencer-ticket/${id}/request-rejection`, requestBody)).data;
  }
  async putInfluencerTicketApprovalById(
    id: APIPaths["/store-app/influencer-ticket/{id}/request-approval"]["put"]["parameters"]["path"]["id"],
  ): Promise<
    APIPaths["/store-app/influencer-ticket/{id}/request-approval"]["put"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.put(`/store-app/influencer-ticket/${id}/request-approval`)).data;
  }
  async putInfluencerTicketCancelById(
    id: APIPaths["/store-app/influencer-ticket/{id}/cancel"]["put"]["parameters"]["path"]["id"],
  ): Promise<
    APIPaths["/store-app/influencer-ticket/{id}/cancel"]["put"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.put(`/store-app/influencer-ticket/${id}/cancel`)).data;
  }
  async putInfluencerTicketUseCancelById(
    id: APIPaths["/store-app/influencer-ticket/{id}/use-cancel"]["put"]["parameters"]["path"]["id"],
  ): Promise<
    APIPaths["/store-app/influencer-ticket/{id}/use-cancel"]["put"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.put(`/store-app/influencer-ticket/${id}/use-cancel`)).data;
  }
  async putInfluencerTicketDeliveryById(
    id: APIPaths["/store-app/influencer-ticket/{id}/delivery"]["put"]["parameters"]["path"]["id"],
  ): Promise<
    APIPaths["/store-app/influencer-ticket/{id}/delivery"]["put"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.put(`/store-app/influencer-ticket/${id}/delivery`)).data;
  }
  async putInfluencerTicketCheckById(
    id: APIPaths["/store-app/influencer-ticket/{id}/check"]["put"]["parameters"]["path"]["id"],
  ): Promise<
    APIPaths["/store-app/influencer-ticket/{id}/check"]["put"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.put(`/store-app/influencer-ticket/${id}/check`)).data;
  }
  async putInfluencerTicketRatingById(
    id: APIPaths["/store-app/influencer-ticket/{id}/rating"]["put"]["parameters"]["path"]["id"],
    requestBody: APIPaths["/store-app/influencer-ticket/{id}/rating"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<
    APIPaths["/store-app/influencer-ticket/{id}/rating"]["put"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.put(`/store-app/influencer-ticket/${id}/rating`, requestBody)).data;
  }
  async postInfluencerTicketChatReportById(
    id: APIPaths["/store-app/influencer-ticket/{id}/chat-report/new"]["post"]["parameters"]["path"]["id"],
    requestBody: APIPaths["/store-app/influencer-ticket/{id}/chat-report/new"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<
    APIPaths["/store-app/influencer-ticket/{id}/chat-report/new"]["post"]["responses"]["201"]["content"]["application/json"]
  > {
    return (await this.post(`/store-app/influencer-ticket/${id}/chat-report/new`, requestBody)).data;
  }
  async getInfluencerTicketChatReportList(
    id: APIPaths["/store-app/influencer-ticket/{id}/chat-report/list-all"]["get"]["parameters"]["path"]["id"],
  ): Promise<
    APIPaths["/store-app/influencer-ticket/{id}/chat-report/list-all"]["get"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.get(`/store-app/influencer-ticket/${id}/chat-report/list-all`)).data;
  }
  async getInfluencerTicketStatusLogList(
    id: APIPaths["/store-app/influencer-ticket/{id}/status-log/list-all"]["get"]["parameters"]["path"]["id"],
  ): Promise<
    APIPaths["/store-app/influencer-ticket/{id}/status-log/list-all"]["get"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.get(`/store-app/influencer-ticket/${id}/status-log/list-all`)).data;
  }
}
