import { APIPaths } from "./api-config";
import BaseApi from "./base-api";

export class CommonApi extends BaseApi {
  async registerImageByLambdaEdge(
    requestBody: APIPaths["/app/image/new"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/image/new"]["post"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.post("/app/image/new", requestBody)).data;
  }
  async registerStoreImageByLambdaEdge(
    requestBody: APIPaths["/store-app/image/new"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/store-app/image/new"]["post"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.post("/store-app/image/new", requestBody)).data;
  }
  async registerAdminImageByLambdaEdge(
    requestBody: APIPaths["/admin-dash/image/new"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/admin-dash/image/new"]["post"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.post("/admin-dash/image/new", requestBody)).data;
  }
}
