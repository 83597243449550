import { APIPaths } from "../api-config";
import BaseApi from "../base-api";

export class StoreStoreApi extends BaseApi {
  async getStoreList(
    parameters: APIPaths["/store-app/store/list"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/store-app/store/list"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (
      await this.get("/store-app/store/list", {
        params: parameters,
      })
    ).data;
  }
  async registerStoreNew(
    requestBody: APIPaths["/store-app/store/new"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/store-app/store/new"]["post"]["responses"]["201"]["content"]["application/json"]> {
    return (await this.post("/store-app/store/new", requestBody)).data;
  }
  async getStoreById(
    id: APIPaths["/store-app/store/{id}"]["get"]["parameters"]["path"]["id"],
  ): Promise<APIPaths["/store-app/store/{id}"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get(`/store-app/store/${id}`)).data;
  }
  async patchStoreById(
    id: APIPaths["/store-app/store/{id}"]["patch"]["parameters"]["path"]["id"],
    requestBody: APIPaths["/store-app/store/{id}"]["patch"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/store-app/store/{id}"]["patch"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.patch(`/store-app/store/${id}`, requestBody)).data;
  }
  async deleteStoreById(
    id: APIPaths["/store-app/store/{id}"]["delete"]["parameters"]["path"]["id"],
  ): Promise<APIPaths["/store-app/store/{id}"]["delete"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.delete(`/store-app/store/${id}`)).data;
  }
  async putRequestStoreById(
    id: APIPaths["/store-app/store/{id}/request"]["put"]["parameters"]["path"]["id"],
  ): Promise<APIPaths["/store-app/store/{id}/request"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put(`/store-app/store/${id}/request`)).data;
  }
  async putCancelRequestStoreById(
    id: APIPaths["/store-app/store/{id}/request-cancel"]["put"]["parameters"]["path"]["id"],
  ): Promise<
    APIPaths["/store-app/store/{id}/request-cancel"]["put"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.put(`/store-app/store/${id}/request-cancel`)).data;
  }
  async putApproveStoreById(
    id: APIPaths["/store-app/store/{id}/open"]["put"]["parameters"]["path"]["id"],
  ): Promise<APIPaths["/store-app/store/{id}/open"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put(`/store-app/store/${id}/open`)).data;
  }
  async putCloseStoreById(
    id: APIPaths["/store-app/store/{id}/close"]["put"]["parameters"]["path"]["id"],
  ): Promise<APIPaths["/store-app/store/{id}/close"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put(`/store-app/store/${id}/close`)).data;
  }
}
