export const QueryKeys = {
  getAuthUser: "getAuthUser",
  getGenreList: "getGenreList",
  getAreaList: "getAreaList",
  getBrandList: "getBrandList",
  getStoreList: "getStoreList",
  fetchInfluencerTicket: "fetchInfluencerTicket",
  fetchInfluencerTicketStatusLog: "fetchInfluencerTicketStatusLog",
  fecthRoomMessage: "fecthRoomMessage",
  fecthAllRoom: "fecthAllRoom",
  getStoreById: "getStoreById",
} as const;
