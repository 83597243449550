// アプリページ系
export const LOGIN_PATH = "/auth/login";
export const FORGOT_PASS_PAGE = "/auth/password-reset";

export const HOME_PATH = "/home";
export const TICKETS_DETAIL_PATH = (query: string) => `/tickets/${query}`;
export const INFLUENCER_SEARCH_PATH = "/influencer-search";
export const FAVORITE_INFLUENCER_PATH = "/favorite-influencer";

export const SHOP_TICKETS_PATH = "/shop-tickets";
export const SHOP_TICKETS_DETAIL_PATH = (query: string) => `/shop-tickets/${query}`;
export const MYPAGE_PATH = "/mypage";
export const MYPAGE_ACCOUNT_INFO_PATH = "/mypage/account-info";
export const MYPAGE_PASSWORD_PATH = "/mypage/password";

export const baseShopTicketsPath = "/shop-tickets";
export const shopTicketsDetailPath = (query: string) => `${baseShopTicketsPath}/${query}`;
export const shopTicketsDetailOnMailOrderPath = (query: string) =>
  `${baseShopTicketsPath}/tickets-detail-on-mail-order/${query}`;

// サポート・利用規約など、その他
export const SUPPORT_LINK_PATH = "https://service.unit-g.com/contact/";
export const TERMS_PATH = "https://drive.google.com/file/d/1PehPleWrJhMC-mhpt8o9bymaqdFHtqya/view?usp=sharing";
export const PRIVACY_POLICY_PATH = "https://unit-g.com/privacy_policy/";
export const UNIT_COMPANY_PATH = "https://unit-g.com/corporation/";
