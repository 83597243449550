import { CircularProgress, Grid } from "@mui/material";
import { useCustomMediaQuery } from "@unit/hooks";
import React from "react";

const QueryProgress: React.FC = () => {
  const { isMobile } = useCustomMediaQuery();
  return (
    <Grid container justifyContent="center" alignItems="center" p={5} pt={isMobile ? 12 : 5}>
      <CircularProgress />
    </Grid>
  );
};
export default QueryProgress;
